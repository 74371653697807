import React from "react";
import './pageA.css';

class PageA extends React.Component {
    constructor(props: {}) {
        super(props);
        this.state = {
            name: ""
        }
    }

    componentDidMount() {
        let canvas: any = document.getElementById('canvas')
        console.log(canvas)
        let ctx: any = null;
        if (canvas) {
            canvas.width = 500
            canvas.height = 500
            ctx = canvas.getContext('2d')
        }

        function clock() {
            if (ctx) {
                ctx.clearRect(0, 0, 500, 500)
                ctx.save()//保存初始状态
                ctx.translate(150, 150)
                ctx.save()//保存坐标平移后的状态

                ctx.beginPath()
                ctx.strokeStyle = '#ff0'
                ctx.lineWidth = 5
                ctx.arc(0, 0, 140, 0, 360 * Math.PI / 180)
                ctx.stroke()

                for (let i = 0; i < 12; i++) {
                    ctx.beginPath()
                    ctx.moveTo(0, -140)
                    ctx.lineTo(0, -120)
                    ctx.rotate(30 * Math.PI / 180)
                    ctx.font = '25px 宋体';
                    ctx.textAlign = 'center';//对齐方式基于fillText的x值
                    ctx.fillText(i + 1, 0, -100);//文本内容和文本的位置
                    ctx.stroke()

                }
                ctx.lineWidth = 2
                ctx.strokeStyle = "#666"
                for (let i = 0; i < 60; i++) {
                    ctx.beginPath()
                    ctx.moveTo(135, 0)
                    ctx.lineTo(130, 0)
                    ctx.rotate(6 * Math.PI / 180)
                    if (i % 5 === 0) continue;
                    ctx.stroke()
                }
                const date = new Date();
                const hour = date.getHours()
                const minute = date.getMinutes()
                const second = date.getSeconds()
                let hourDegree = (hour % 12 / 12 * 360 * Math.PI / 180 + minute / 60 * 30 * Math.PI / 180) - Math.PI / 2
                let minuteDegree = minute / 60 * 360 * Math.PI / 180 - Math.PI / 2
                let secondDegree = second / 60 * 360 * Math.PI / 180 - Math.PI / 2
//时针
                ctx.restore()//恢复坐标平移后的状态
                ctx.save()//保存坐标平移后的状态
                ctx.beginPath()
                ctx.lineWidth = 6
                ctx.lineCap = "round"
                ctx.rotate(hourDegree)
                ctx.moveTo(0, 0)
                ctx.lineTo(50, 0)
                ctx.stroke()
//分针
                ctx.restore()//恢复坐标平移后的状态
                ctx.save()//保存坐标平移后的状态
                ctx.beginPath()
                ctx.lineWidth = 4
                ctx.rotate(minuteDegree)
                ctx.moveTo(0, 0)
                ctx.lineTo(65, 0)
                ctx.stroke()
//秒针
                ctx.restore()//恢复坐标平移后的状态
                ctx.beginPath()
                ctx.lineWidth = 2
                ctx.rotate(secondDegree)
                ctx.moveTo(0, 0)
                ctx.lineTo(80, 0)
                ctx.strokeStyle = "red"
                ctx.stroke()
                ctx.restore()//恢复到初始状态
                window.requestAnimationFrame(clock)
            }
        }

        clock()
    }

    render() {
        return (
            <div className="content">
                <canvas style={{marginTop: '50px'}} id="canvas" width="300" height="300"></canvas>
            </div>
        )
    }
}
export default PageA;
