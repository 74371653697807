import React, {Component} from "react";
import {parseTime} from "../utils/utils"

class Expand extends Component<{},{ items:Array<string> ,time:string}> {
    constructor(props:{}) {
        super(props);
        this.state = {
            items:[],
            time:''
        }
    }
    componentDidMount() {
        this.init();
    }

    init(){
        let now: any = new Date();
        let date = parseTime(now, '{y}年{m}月{d}日 星期{a}');
        let arr:Array<string> =[]
        for (let i = 1; i <= 5; i++) {
            arr.push(parseTime(now - i * 1000 * 60 * 60 * 24, '{y}年{m}月{d}日'));
        }
        this.setState(prevState => ({
            time: date,
            items: arr
        }));
    }
    render() {
        return (
            <div className="App-expand">
                <div className="nowtime">{this.state.time}</div>
                <ul>
                    {this.state.items.map((item: string,index) => (
                        <li key={index}>{item}</li>
                    ))}
                </ul>
            </div>
        );
    }
}
export default Expand;
