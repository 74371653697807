import React, { Component } from 'react';
import { Link } from 'react-router-dom';
class MySlider extends Component {
    render() {
        return (
            <div className="App-slider">
                slider
                <ul>
                    <li><Link to="/">首页</Link></li>
                    <li><Link to="/login">Login</Link></li>
                    <li><Link to="/game">game</Link></li>
                    <li><Link to="/imgfindkeyOcr">imgfindkeyOcr</Link></li>
                </ul>
            </div>
        )
    }
}
export default MySlider;
