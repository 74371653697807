import axios from 'axios'
import { Toast } from 'zarm'

const MODE = "development" // 环境变量
//const MODE = import.meta.env.MODE
axios.defaults.baseURL = MODE === 'development' ? 'https://www.mrhuweb.cn/api' : 'https://www.mrhuweb.cn/api'
axios.defaults.withCredentials = false
axios.defaults.headers['X-Requested-With'] = 'XMLHttpRequest'
axios.defaults.headers['Authorization'] = `${localStorage.getItem('token') || null}`
axios.defaults.headers.post['Content-Type'] = 'application/json'

axios.interceptors.response.use(res => {
  if (typeof res.data !== 'object') {
    Toast.show('服务端异常！')
    return Promise.reject(res)
  }
  console.log(res)
  if (res.status !== 200) {
    return Promise.reject(res.data)
  }
  if (res.status === 401) {
    window.location.href = '/login'
  }
  return res
})

export default axios
