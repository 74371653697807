import drawBase from "./drawBase";
class Meteor extends drawBase{
    transparency:any;
    size:any;
    k:number;
    speed:number;
    fillStyle:Array<number>;
    constructor(ctx:CanvasRenderingContext2D,imgData:any,k:number) {
        super(ctx,imgData);
        this.transparency=1
        this.size=Math.random()*2+2
        this.k=k
        console.log(k)
        this.speed=(Math.random()*2+0.5)
        this.fillStyle=[Math.random()*255,Math.random()*255,Math.random()*255];
    }
    drawStart(){
        if(this.k>0.75){
            this.y=this.y+this.speed;
            this.x=(this.k*800-this.y)/this.k;
        }else{
            this.x=this.x-this.speed;
            this.y=this.k*(800-this.x)
        }


        if(this.x>=0 && this.y>=0){
            this.ctx.clearRect(0,0,800,600)
            this.ctx.beginPath();
            this.ctx.lineWidth = 1;
            this.ctx.fillStyle = `rgb(${this.fillStyle[0]},${this.fillStyle[1]},${this.fillStyle[2]},1)`;
            this.ctx.arc(this.x,this.y, this.size, 0, 2 * Math.PI);
            this.ctx.fill();

            for(let i=0;i<60;i++){
                this.drawTail(i)
            }
            this.content.drawImage(this.canvas,0,0)
        }
    }
    drawTail(opacity:number){
        let transparency
        if(opacity<20){
            transparency =this.transparency-0.03*opacity
        }else {
            transparency =0.4-0.01*(opacity-20)
        }
        let x,y;
        if(this.k>0.75){
            y=this.y-(this.size*40/50)*opacity;
            x=(this.k*800-y)/this.k;
        }else{
            x=this.x+(this.size*40/50)*opacity
            y=this.k*(800-x)
        }

        if(transparency>0){
            this.ctx.beginPath();
            this.ctx.fillStyle = `rgb(${this.fillStyle[0]},${this.fillStyle[1]},${this.fillStyle[2]},${transparency})`;
            this.ctx.arc(x,y, this.size, 0, 2 * Math.PI);
            this.ctx.closePath();
            this.ctx.fill();
        }
    }
}

export default Meteor

