import { createStore } from 'redux';
//import mitter from "../utils/eventbus";

/**
 * 这是一个 reducer，形式为 (state, action) => state 的纯函数
 * 描述了 action 如何把 state 转变成下一个 state
 *
 * state 的形式取决于你，可以是基本类型、数组、对象
 * 当 state 修改时需要返回全新的对象，而不是修改传入的参数
 *
 * 下面例子使用 `switch` 语句和字符串来做判断，但你可以写帮助类（helper）
 * 根据不同的约定（如方法映射）来判断，只要适用于你的项目就可以了
 */
function currentRoute(state = {
    type:"",
    path:'/index',
    messagechange:""
}, action) {
    switch (action.type) {
        case 'path': return {
            ...state,
            type: action.type,
            path: action.data
        };
        case 'messagechange': return {
            ...state,
            type: action.type,
            messagechange: action.data
        };
        default : return state;
    }
}

// 创建 Redux Store 来存放应用状态
// API 是 { subscribe, dispatch, getState }
let store = createStore(currentRoute);

// 可以手动订阅更新，也可以事件绑定到视图层
/*store.subscribe(() => {

});*/

export default store


