class DrawBase {
    canvas:any;
    ctx:any;
    content:CanvasRenderingContext2D;
    imgData:any;
    x:number;
    y:number;
    constructor(ctx:CanvasRenderingContext2D,imgData:any) {
        this.content=ctx;
        this.imgData=imgData;
        this.canvas=document.createElement('canvas')
        this.canvas.width = 800;
        this.canvas.height = 600;
        this.x=800
        this.y=0
        this.ctx=this.canvas.getContext('2d')
    }
    drawStart(){

    }
}

export default DrawBase

