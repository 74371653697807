import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router";
import store from "../reducer";



function MyMenu(items: any[]): JSX.Element{
    const navigate = useNavigate();
    const change = (e:any,path : string):void => {
        navigate(path);
    };
    let [path, setPath] = useState(store.getState().type);
    useEffect(() => {
        console.log('useEffect')
        store.subscribe(()=>{
            if(store.getState().type==='path'){
                setPath(store.getState().path)
            }
        },[])
/*        mitter.addListener('update', (msg: any) =>{
            console.log(path)
            mitter.removeAllListeners('update')
        })*/
    });

    return (<div className="my-menu">
        {items.map(el=>(
            <div key={el.path} className={`${path === el.path ? 'active' : ''} my-menu-a`} onClick={(e)=>change(e,el.path)}>{el.text}</div>
        ))}
    </div>)
};

export default MyMenu;
