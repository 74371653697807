import React, { useState} from "react";
import './login.css';
import {post} from "../../utils";
import {connect, sendMessage} from "../../utils/websocket";
import {Button, Form, Input} from "antd";
import {useNavigate} from "react-router";
import axios from "../../utils/axios";

const Login =()=>{
        const [username,setUsername] = useState('');
        const [ password,setPassword] = useState('');
    const navigate  = useNavigate();

        let login = async ()=>{
            const {data}  = await post('login',{username,password});
            console.log('login',data,data.code===200)
            if(data.code===200){
                console.log('getUserInfo',data.data.id)
                axios.defaults.headers['Authorization'] = `${data.data.token || null}`
                getUserInfo(data.data.id)
            }
        }
    let getUserInfo = async (id:any)=>{
        const { data } = await post('userInfo',{id});
        console.log(data)
        if(data.code===200){
            localStorage.setItem('userInfo',JSON.stringify(data.data))
            localStorage.setItem('token',JSON.stringify(data.data.token))
            connect( 'wss://www.mrhuweb.cn/websoket?token='+data.data.token,()=>{
                sendMessage('connect',localStorage.getItem('token'))
            })
            navigate('/index')
        }
    }
    const onFinish = (values: any) => {
        console.log('Success:', values);
        login()
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };
        return (
            <div className="page">
                <div>login:</div>
                <Form
                    name="basic"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    style={{ maxWidth: 600 }}
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <Form.Item
                        label="Username"
                        name="username"
                        rules={[{ required: true, message: 'Please input your username!' }]}
                    >
                        <Input  value={username} onChange={(e: any):any=> {
                            setUsername(e.target.value)
                        }}/>
                    </Form.Item>

                    <Form.Item
                        label="Password"
                        name="password"
                        rules={[{ required: true, message: 'Please input your password!' }]}
                    >
                        <Input.Password  value={password} onChange={(e: any):any=> {
                            setPassword(e.target.value)
                        }}/>
                    </Form.Item>
                    <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                        <Button type='primary' htmlType="submit">Submit</Button>
                    </Form.Item>
                </Form>
            </div>
        )
}

export default Login
