import drawBase from "./drawBase";

class Particle extends drawBase {
    size: any;
    duration:number;
    speed: number;
    fillStyle: Array<number>;
    particles:Array<any>;
    arr:Array<any>;
    imageData: any;
    constructor(ctx: CanvasRenderingContext2D, imgData: any) {
        super(ctx, imgData);
        this.arr=[]
        this.particles=[]
        this.size = 0
        this.duration=0
        this.speed = (Math.random() * 2 + 0.5)
        this.fillStyle = [Math.random() * 255, Math.random() * 255, Math.random() * 255];
        this.drawStart()
    }

    drawStart() {
        this.ctx.clearRect(0, 0, 800, 600)
        this.ctx.font = '128px serif';
        this.ctx.strokeStyle  = 'red';
        this.ctx.strokeText('胡超群', 208, 236);
        this.imageData=this.ctx.getImageData(0,0,800,600);
        console.log(this.imageData)
        for (let y = 0; y < 600; y++) {
            for(let x=0;x<800;x++){
                if(this.imageData.data[(x+y*800)*4+3]!==0){
                    this.imageData.data[(x+y*800)*4]=Math.random() * 255
                    this.imageData.data[(x+y*800)*4+1]=Math.random() * 255
                    this.imageData.data[(x+y*800)*4+2]=Math.random() * 255
                    this.particles.push({
                        x:x,
                        y:y,
                        color: `rgb(${this.imageData.data[(x+y*800)*4]},${this.imageData.data[(x+y*800)*4+1]},${this.imageData.data[(x+y*800)*4+2]},1)`
                    })
                    this.arr.push({
                        x:800,
                        y:600,
                        color: `rgb(${this.imageData.data[(x+y*800)*4]},${this.imageData.data[(x+y*800)*4+1]},${this.imageData.data[(x+y*800)*4+2]},1)`
                    })
                }
            }
        }
       // this.ctx.putImageData(this.imageData,0,0)
       // this.drawTail()
        this.content.drawImage(this.canvas, 0, 0)
    }

    drawTail() {
        console.log(this.particles.length)
        this.ctx.clearRect(0, 0, 800, 600)
        this.size<this.particles.length?this.size+=10:this.size=this.particles.length
        for (let i = 0; i < this.particles.length; i++) {
            if(i<this.size && this.arr[i].x!==this.particles[i].x){
                let x=this.arr[i].x-Math.random()*(800-this.particles[i].x)/50
                this.arr[i].x=x<this.particles[i].x?this.particles[i].x:x
                this.arr[i].y=600+(this.arr[i].x-800)*(600-this.particles[i].y)/(800-this.particles[i].x)
            }
            this.ctx.beginPath();
            this.ctx.lineWidth = 1;
            this.ctx.fillStyle = this.arr[i].color;
            this.ctx.arc(this.arr[i].x,this.arr[i].y, 1, 0, 2 * Math.PI);
            this.ctx.closePath();
            this.ctx.fill();
        }
        this.content.drawImage(this.canvas, 0, 0)
    }
}

export default Particle

