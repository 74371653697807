import React from 'react';
import MyMenu from "./myMenu";
import { useNavigate } from 'react-router';
import Search from "antd/es/input/Search";



const MyHeader: React.FC = () => {
    const navigate = useNavigate();
    const change = (e:any,path : string):void => {
            navigate(path);
    };
    const menus =[{
        path: "/index/a",
        text: 'pageA'
    },{
        path: "/index/b",
        text: 'pageB'
    },{
        path: "/index/c",
        text: 'pageC'
    },{
        path: "/index/d",
        text: 'pageD'
    }]


    const onSearch = (value: string) => console.log(value);
    return (<div className="App-header">
        <div className="logo" onClick={(e)=>change(e,'/index')}>我的空间</div>
        <div>
            <Search placeholder="input search text" allowClear onSearch={onSearch} style={{ width: 200 }} />
            {MyMenu(menus)}
        </div>
    </div>)
};

export default MyHeader;

/*class MyHeader extends Component<,any> {
    constructor(props: any) {
        super(props);
        this.change = this.change.bind(this);
    }
    change=(e:any,path : string):void=>{
        console.log(e,path)
        this.props.navigate(path);
    }
    render() {
        return (
            <div className="App-header">
                Header
                <Button type="primary" onClick={ (event: any):any=> this.change(event,'/page/a')}>pageA</Button>
                <Button type="primary" onClick={ (event: any):any=> this.change(event,'/page/b')}>pageB</Button>
            </div>
        )
    }

}

export default MyHeader;*/
