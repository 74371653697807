import PageA from "../views/pageA/pageA"
import PageB from "../views/pageB/pageB"
import {Navigate} from "react-router";
import Index from "../views/index";
import Login from "../views/login";
import React from "react";
import PageC from "../views/pageC/pageC";
import PageD from "../views/pageD/pageD";
import Game from "../views/game";
import ImgfindkeyOcr from "../views/imgfindkeyOcr";

export const main = [
    {
        path: '/',
        element: <Navigate to='/index'></Navigate>
    },
    {
        path: '/login',
        element: <Login></Login>
    },
    {
        path: '/game',
        element: <Game></Game>
    },
    {
        path: '/imgfindkeyOcr',
        element: <ImgfindkeyOcr></ImgfindkeyOcr>
    },
    {
        path: '/index',
        element: <Index></Index>,
        children:[
            {path: 'a', element: <PageA></PageA>},
            {path: 'b', element: <PageB></PageB>},
            {path: 'c', element: <PageC></PageC>},
            {path: 'd', element: <PageD></PageD>}
        ]
    },

]

export const menus = [    // 菜单相关路由
    {path: '/index/a', element: <PageA></PageA>},
    {path: '/index/b', element: <PageB></PageB>}
]

export const routerConfig =  {
    main, menus
}
