class Bubble {
    canvas:any;
    ctx:any;
    content:CanvasRenderingContext2D;
    imgData:any;
    transparency:any;
    size:any;
    x:number;
    y:number;
    constructor(ctx:CanvasRenderingContext2D,imgData:any,last:Array<number>) {
        this.content=ctx;
        this.imgData=imgData;
        this.x=last[0];
        this.y=last[1];
        this.size=5
        this.transparency=1
        this.canvas=document.createElement('canvas')
        this.canvas.width = 800;
        this.canvas.height = 600;
        this.ctx=this.canvas.getContext('2d')
    }
    drawStart(){
        if(this.transparency>=0){
            this.size+=0.1
            this.transparency-=0.001
            this.ctx.clearRect(0,0,800,600)
         //   this.content.putImageData(this.imgData,0,0)
            this.ctx.beginPath();
            this.ctx.lineWidth = 1;
            this.ctx.fillStyle = 'black';
            this.ctx.arc(this.x,this.y, this.size, 0, 2 * Math.PI);
            this.ctx.strokeStyle = `rgba(0, 0, 0, ${this.transparency})`;
            this.ctx.stroke();
            this.ctx.closePath();
            this.content.drawImage(this.canvas,0,0)
        }
    }
}

export default Bubble

