import React from 'react';
import 'antd/dist/reset.css';
import './App.css';
import { useRoutes} from 'react-router';
import {main} from "./router";


export default function App() {
    // 导入路由
    const elements = useRoutes(main);

    return <>{elements}</>;
}
