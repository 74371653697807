import React, { Component } from 'react';
import {
    ReloadOutlined,
    SyncOutlined,
} from '@ant-design/icons';
import axios from 'axios'
import {Select} from "antd";
import qs from 'qs';

class Crumbs extends Component<{},{
    type:number,
    content:string,
    key:number
}> {
    private intervalTime: NodeJS.Timer | undefined;
    private typeOption:  Array<any>;
    constructor(props:any) {
        super(props);
        let arr=JSON.parse(localStorage.getItem('speakCollection') || '[]')
        this.state={
            type:1,
            content:arr.length>0?arr[arr.length-1]:"",
            key:1
        }
        this.typeOption = [
            {value: 1, label: '早安心语',url:"https://apis.tianapi.com/zaoan/index"},
            {value: 2, label: '名人名言',url:"https://apis.tianapi.com/mingyan/index"},
            {value: 3, label: '彩虹屁',url:"https://apis.tianapi.com/caihongpi/index"},
            {value: 4, label: '土味情话',url:"https://apis.tianapi.com/saylove/index"},
        ];
    }

    componentDidMount() {
        if(!this.state.content){
            this.init()
        }
    }

    async init() {
        if(!this.intervalTime){
            this.setState(prevState => ({
            ...prevState,
            key:this.state.key+1
            }))
            this.intervalTime=setTimeout(async ()=>{
                if(this.state.type===2){
                    let {data} = await axios.post(this.typeOption[this.state.type-1].url,qs.stringify({
                        key:"93eebb46520ff8a7f6a41e0bedbb51e6",
                        num:10,
                        typeid:Math.ceil(Math.random()*100)%46
                    }),{headers:{'Content-Type': 'application/x-www-form-urlencoded'}})
                    if(data.code===200){
                        let arr=JSON.parse(localStorage.getItem('famousCollection') || '[]')
                        localStorage.setItem('famousCollection',JSON.stringify(arr.concat(data.result.list)))
                        clearInterval(this.intervalTime)
                        this.intervalTime=undefined
                        this.setState(prevState => ({
                            ...prevState,
                            content:data.result.list[0].content+'  ——'+data.result.list[0].author
                        }))
                    }
                }else{
                    let {data} = await axios.get(`${this.typeOption[this.state.type-1].url}?key=93eebb46520ff8a7f6a41e0bedbb51e6`)
                    if(data.code===200){
                        let arr=JSON.parse(localStorage.getItem('speakCollection') || '[]')
                        arr.push(data.result.content)
                        localStorage.setItem('speakCollection',JSON.stringify(arr))
                        clearInterval(this.intervalTime)
                        this.intervalTime=undefined
                        this.setState(prevState => ({
                            ...prevState,
                            content:data.result.content
                        }))
                    }
                }
            },1000)
        }
    }
    handleChange(value: any){
        this.setState(prevState => ({
            ...prevState,
            type:value
        }))
        this.init()
        console.log(`selected ${value}`);
    };
    iconShow(){
        if(!this.intervalTime)
            return <ReloadOutlined />
        else
            return <SyncOutlined spin />
    }
    render() {
        return (
            <div className="App-crumbs">
                <span>{this.state.content}</span>
                <span onClick={(e)=> {
                    this.init()
                }}>{this.iconShow()}</span>
                <Select
                    defaultValue={this.state.type}
                    style={{ width: 120,marginLeft:100 }}
                    onChange={(e)=>this.handleChange(e)}
                    options={this.typeOption}
                />
            </div>
        )
    }
}
export default Crumbs;
