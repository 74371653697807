import React from "react";

class PageB extends React.Component<{},{name:string}> {
    constructor(props:{}) {
        super(props);
        this.state={
            name:""
        }
    }
    componentDidMount(){
        let canvas : any = document.getElementById('canvas')
        console.log(canvas)
        let cxt : any=null;
        let startTime=new Date().getTime()
        let executionTime=0
        let fps=0
        let status=-1
        let cycleTime=600
        let Time:any=null
        if(canvas){
            canvas.width=500
            canvas.height=500
            cxt=canvas.getContext('2d')
        }

        function heard() {
            if(Time){
                clearTimeout(Time)
            }
            if(fps%46===0){
                status=status*-1
            }else{
                cxt.clearRect(0, 0, 500, 500)
                cxt.save()
                cxt.font = '25px 宋体';
                cxt.textAlign = 'center';//对齐方式基于fillText的x值
                executionTime=Math.floor((new Date().getTime()-startTime)/1000)

                cxt.fillText(executionTime, 150,150);//文本内容和文本的位置

                //创建一个路径
                cxt.beginPath();

                //移动位置点 起始点
                cxt.moveTo(0,400);
                //路障
                cxt.lineTo(400-2/3*(fps%cycleTime),400);
                cxt.lineTo(400-2/3*(fps%cycleTime)+5,380);
                cxt.lineTo(400-2/3*(fps%cycleTime)+10,400);
                // 描述行进路径
                cxt.lineTo(410,400);
                // 绘制图形
                cxt.strokeStyle = '#000';
                cxt.stroke();//画线


                cxt.beginPath();//起始路径
                cxt.lineWidth = 1;//线宽度为1
                cxt.strokeStyle = '#000';//笔触的颜色
                cxt.arc(100, 300, 20, 0 * Math.PI, 2 * Math.PI);//绘制弧，中心点（200，175），半径175
                cxt.fillStyle = '#0bb0da';//设置填充时的颜色
                cxt.fill();//填充颜色
                //    cxt.stroke();//绘制路径

                cxt.beginPath();
                cxt.fillStyle = '#0bb0da';
                cxt.moveTo(90, 320);//将路径移到点（110，110），不创建线条
                cxt.quadraticCurveTo(88, 340, 85, 360);//创建二次贝塞尔曲线,控制点(-10,200),结束点(120,315)
                cxt.lineTo(115, 360);//添加一个新点，然后在画布中创建从（110，110）到（280，315）的线条
                cxt.quadraticCurveTo(112, 340, 110, 320);
                cxt.lineTo(90, 320);
                cxt.fill();

                cxt.fillStyle = '0bb0da';


                cxt.save()
                cxt.translate(93,355)
                if(status===-1){
                    cxt.rotate(Math.PI/180*(fps%46*-1))
                }else{
                    cxt.rotate(Math.PI/180*((fps%46-45)))
                }

                cxt.fillRect(-4,0,8,35); //前两个表示，x，y的坐标，后两个是宽高
                cxt.restore()//恢复坐标平移后的状态
                //    cxt.fillRect(89,360,8,30); //前两个表示，x，y的坐标，后两个是宽高

                cxt.save()
                cxt.translate(107,355)
                if(status===-1){
                    cxt.rotate(Math.PI/180*(fps%46))
                }else{
                    cxt.rotate(Math.PI/180*((45-fps%46)))
                }
                cxt.fillRect(-4,0,8,35); //前两个表示，x，y的坐标，后两个是宽高

                cxt.restore()//恢复坐标平移后的状态
            }
            fps=Math.floor((new Date().getTime()-startTime)/100)
            window.requestAnimationFrame(heard)

        }
        heard()
    }

    render() {
        return (
            <div>
                <canvas style={{marginTop:'50px'}} id="canvas" width="300" height="300"></canvas>
            </div>
        )
    }
}
export default PageB;
