import store from "../reducer";

const W3CWebSocket = require('websocket').w3cwebsocket;

export let client=null;

export function sendMessage(type,content){
    if(client){
        client.send(JSON.stringify({
            type: type,
            username: JSON.parse(localStorage.getItem('userInfo') || '{}')?.name,
            content: content
        }));
    }
}

export function connect(url,callback){
    console.log('连接：',client)
    if(client!==null){
         let time=setInterval(()=>{
             if(client?.readyState===1){
                callback()
                 clearInterval(time)
             }
        },1000)
    }else{
        const headers = {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
        };
        console.log('重新连接')
        client = new W3CWebSocket(url,'','',{headers});
        client.onopen =() => {
            callback()
            console.log('WebSocket Client Connected');
        };
        client.onmessage = (message) => {
            const dataFromServer = JSON.parse(message.data);
            store.dispatch({ type: 'messagechange',data:dataFromServer.utf8Data });
        //    onmessage(JSON.parse(JSON.parse(dataFromServer.utf8Data).content))
            console.log(dataFromServer)
        };
        client.onerror=(event) => {
            console.error("WebSocket error occurred:", event);
        };
        client.onclose=(event) => {
            console.log("WebSocket connection closed.");
            client = null;
        };
    }

}
