import React, {Component} from "react";
import 'antd/dist/reset.css';
import '../../app.scss';
import MyHeader from "../../components/header";
import Crumbs from "../../components/crumbs";
import MyMain from "../../components/main";
import MySlider from "../../components/slider";
import Expand from "../../components/expand";
import Communicate from "../communicate";



class Index extends Component {
    render() {
        return (
                <div className="App">
                    <MyHeader/>
                    <div className="App-content">
                        <MySlider/>
                        <div className="App-right">
                            <Crumbs/>
                            <MyMain/>
                            <div className="App-floot"><a href='https://beian.miit.gov.cn' target='_blank' rel='noreferrer'>皖ICP备2023025533号-1</a></div>
                        </div>
                        <Expand/>
                    </div>
                    <Communicate/>
                </div>
        )
    }
}

export default Index
