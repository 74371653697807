import React from "react";
import './pageD.css';
/*import { Cartesian3, createOsmBuildingsAsync, Ion, Math as CesiumMath, Terrain, Viewer } from 'cesium';*/
/*import * as cesium  from "cesium/Cesium";
import 'cesium/Widgets/widgets.css';//解决cesium页面混乱问题
import initCesium from '../../utils/Cesium'*/

class PageD extends React.Component<{},{}> {
    constructor(props: any) {
        super(props);
        this.state={}
    }

    componentDidMount(){
    }
    componentWillUnmount(){
        this.init()
    }
    init=async ()=>{
        var context:any = null;
        var gCanvas:any = null;
        gCanvas = document.getElementById("star");
        gCanvas.width = 800;
        gCanvas.height = 300;
        context = gCanvas.getContext("2d");
        context.clearRect(0, 0, 800,300);

      /*  let r,R,x,y;
        context.strokeStyle = "#c0c4cc";
        for (let j = 0; j < 5; j++) {
            r = 15;
            R = 25;
            x = 35 + j * 55;
            y = 100;
            context.beginPath();
            for (let i = 0; i < 5; i++) {
                context.lineTo(
                    Math.cos(((18 + i * 72 ) / 180) * Math.PI) * R + x,
                    -Math.sin(((18 + i * 72 ) / 180) * Math.PI) * R + y
                );
                context.lineTo(
                    Math.cos(((54 + i * 72 ) / 180) * Math.PI) * r + x,
                    -Math.sin(((54 + i * 72 ) / 180) * Math.PI) * r + y
                );
            }
            context.closePath();
            context.stroke();
            if (j <= 1) {
                context.fillStyle = "yellow";
                context.fill();
            }
            if(j==1){
                context.clearRect(x+Math.cos(0.1 * Math.PI) * R*(2*0.8-1), y-R, 50,50);
                r = 15;
                R = 25;
                x = 35 + j * 55;
                y = 100;
                context.beginPath();
                for (let i = 0; i < 5; i++) {
                    context.lineTo(
                        Math.cos(((18 + i * 72 ) / 180) * Math.PI) * R + x,
                        -Math.sin(((18 + i * 72 ) / 180) * Math.PI) * R + y
                    );
                    context.lineTo(
                        Math.cos(((54 + i * 72 ) / 180) * Math.PI) * r + x,
                        -Math.sin(((54 + i * 72 ) / 180) * Math.PI) * r + y
                    );
                }
                context.closePath();
                context.stroke();
            }

        }*/

        context.font = '50px serif';
        context.strokeStyle  = 'red';
        context.strokeText('胡超群', 8, 50);
     /*   context.fillStyle = 'green';
        context.fillRect(10, 10, 100, 100);*/
        let imageData=context.getImageData(0,0,800,300);
        let particles:any[]=[];
        let arr:any[]=[];

        for (let y = 0; y < 300; y++) {
            for(let x=0;x<800;x++){
                if(imageData.data[(x+y*800)*4+3]!==0){
                    imageData.data[(x+y*800)*4]=Math.random() * 255
                    imageData.data[(x+y*800)*4+1]=Math.random() * 255
                    imageData.data[(x+y*800)*4+2]=Math.random() * 255
                    particles.push({
                        x:x*5,
                        y:y*5,
                        color: `rgb(${imageData.data[(x+y*800)*4]},${imageData.data[(x+y*800)*4+1]},${imageData.data[(x+y*800)*4+2]},1)`
                    })
                    arr.push({
                        x:800,
                        y:300,
                        color: `rgb(${imageData.data[(x+y*800)*4]},${imageData.data[(x+y*800)*4+1]},${imageData.data[(x+y*800)*4+2]},1)`
                    })
                }
            }
      }
        let size=0
      //  context.clearRect(0, 0, 800,300);
      //  context.scale(0.8, 0.5);
        function f() {
            context.clearRect(0, 0, 800,300);
            size<particles.length?size+=5:size=particles.length
            for (let i = 0; i < particles.length; i++) {
                if(i<size&&arr[i].x!==particles[i].x){
                    let x=arr[i].x-Math.random()*(800-particles[i].x)/50
                    arr[i].x=x<particles[i].x?particles[i].x:x
                    arr[i].y=300+(arr[i].x-800)*(300-particles[i].y)/(800-particles[i].x)

                    console.log()
                }
                context.beginPath();
                context.lineWidth = 1;
                context.fillStyle = arr[i].color;
                context.arc(arr[i].x,arr[i].y, 1, 0, 2 * Math.PI);
                context.closePath();
                context.fill();
            }
            requestAnimationFrame(f);
        }
        f()
        //context.putImageData(imageData,0,0,0,0,800,300)
    }
    render() {
        return (
            <div>
                <div id="container" className="box">
                    <canvas style={{marginTop:'50px',border:'1px #000 solid'}} id="star" width="300" height="300"></canvas>
                </div>
            </div>
        )
    }
}
export default PageD;
