import React from "react";
import './pageC.css';
import axios from 'axios'




class PageC extends React.Component{
    componentWillMount() {

    }

    componentDidMount(){
        this.init()
    }
    init =()=>{
        var gCtx:any = null;
        var gCanvas:any = null;

        var stype=0;
        var gUM=false;

        var v:any=null;
        var vidhtml = '<video id="v" autoplay></video>';
        function initCanvas(w:any,h:any)
        {
            gCanvas = document.getElementById("qr-canvas");
          /*  gCanvas.style.width = w + "px";
            gCanvas.style.height = h + "px";*/
            gCanvas.width = w;
            gCanvas.height = h;
            gCtx = gCanvas.getContext("2d");
            gCtx.clearRect(0, 0, w, h);
        }


        function captureToCanvas() {
            if(stype!==1)
                return;
            if(gUM)
            {
                try{
                    gCtx.drawImage(v,0,0);
                    try{
                        //检测证件
                        const AK = "34NjEF3CubFzTE7dEt20QAdL"
                        const SK = "K8lfkDC0aIKWOF7In63y0dvmlYoGGwHG"

                        axios.post('/baidubce?grant_type=client_credentials&client_id=' + AK +'&client_secret=' + SK,{
                            'id_card_side': 'front'
                        }).then(res=>{
                            axios.post('/idcard?access_token='+res.data.access_token,{
                                'id_card_side': 'front',
                                'image':gCanvas.toDataURL()
                            },{'headers': {
                                    'Content-Type': 'application/json',
                                    'Accept': 'application/json'
                                }}).then(res1=>{
                                console.log(res1)
                            })
                        })
                    }
                    catch(e){
                        console.log(e);
                        setTimeout(captureToCanvas, 500);
                    };
                }
                catch(e){
                    console.log(e);
                    setTimeout(captureToCanvas, 500);
                };
            }
        }


        function isCanvasSupported(){
            var elem = document.createElement('canvas');
            return !!(elem.getContext && elem.getContext('2d'));
        }
        function success(stream:any)
        {

            v.srcObject = stream;
            v.play();

            gUM=true;
            setTimeout(captureToCanvas, 500);
        }

        function error(error:any)
        {
            gUM=false;
            return;
        }

        function load()
        {
            if(isCanvasSupported() && window.File && window.FileReader)
            {
                initCanvas(400, 300);

                setwebcam();
            }
            else
            {

                alert("您当前浏览器不支持扫码,请安装UC浏览器试试!");
            }
        }

        function setwebcam()
        {

            var options:any = true;
            if(navigator.mediaDevices && navigator.mediaDevices.enumerateDevices)
            {
                try{
                    navigator.mediaDevices.enumerateDevices()
                        .then(function(devices) {
                            devices.forEach(function(device) {
                                if (device.kind === 'videoinput') {
                                    if(device.label.toLowerCase().search("back") >-1)
                                        options={'deviceId': {'exact':device.deviceId}, 'facingMode':'environment'} ;
                                }
                                console.log(device.kind + ": " + device.label +" id = " + device.deviceId);
                            });
                            setwebcam2(options);
                        });
                }
                catch(e)
                {
                    console.log(e);
                }
            }
            else{
                console.log("no navigator.mediaDevices.enumerateDevices" );
                setwebcam2(options);
            }

        }

        function setwebcam2(options:any)
        {
            console.log(options);
       //     document.getElementById("result").innerHTML="- scanning -";
            if(stype===1)
            {
                setTimeout(captureToCanvas, 500);
                return;
            }
            var n:any=navigator;
            let el:any=document.getElementById("outdiv")
            el.innerHTML = vidhtml;
            v=document.getElementById("v");


            if(n.mediaDevices.getUserMedia)
            {
                n.mediaDevices.getUserMedia({video: options, audio: false}).then(function(stream:any){
                    console.log(stream)
                    success(stream);
                }).catch(function(error1:any){
                    console.log(error1)
                    error(error1)
                });
            }
            else
            if(n.getUserMedia)
            {

                n.getUserMedia({video: options, audio: false}, success, error);
            }
            else
            if(n.webkitGetUserMedia)
            {

                n.webkitGetUserMedia({video:options, audio: false}, success, error);
            }

            stype=1;
            setTimeout(captureToCanvas, 500);
        }

        load();
        setwebcam();
    }

    render() {
        return (
            <div>
                <div className="content">
                    <canvas style={{marginTop:'50px',border:'1px #000 solid'}} id="qr-canvas" width="300" height="300"></canvas>
                    <div id="outdiv">
                    </div>
                </div>
            </div>
        )
    }
}
export default PageC;
