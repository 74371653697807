import React from "react";
import {Howl} from 'howler';
import './index.scss'
import Bubble from "./components/bubble";
import store from "../../reducer";
import {connect, sendMessage} from "../../utils/websocket";
import Bullet from "./components/bullet";
import Meteor from "./components/meteor";
import Particle from "./components/particle";
class Game extends React.Component<{}, {
    ctx:any,
    status:any,
    x:number,
    y:number,
    isClickCanvas:boolean,
    last:Array<number>,
    isMoveCanvas:boolean,
    endx:number,
    endy:number,
    movePath:Array<any>,
    width:number,
    height:number,
    now:Array<number>,
    imgData:any,
}>{
    constructor(props:any) {
        super(props);
        this.state = {
            ctx: null,
            status: 0,
            x: 0,
            y: 0,
            isClickCanvas: false,
            last: [],
            movePath:[],
            isMoveCanvas: false,
            endx: 0,
            endy : 0,
            width : 0,
            height: 0,
            now: [0,0],
            imgData:null
        }
        this.bubbles=[]
        this.bullets=[]
        this.meteors=[]
        this.particles=[]
        this.partnerMovePath=[]
        this.canvas=document.createElement('canvas')
        this.canvas.style.width = 800;
        this.canvas.style.height = 600;
        this.canvas.width = 800;
        this.canvas.height = 600;
        this.ctx=this.canvas.getContext('2d')



        this.bgcanvas=document.createElement('canvas')
        this.bgcanvas.style.width = 800;
        this.bgcanvas.style.height = 600;
        this.bgcanvas.width = 800;
        this.bgcanvas.height = 600;
        this.bgctx=this.bgcanvas.getContext('2d')

        this.bgcanvas_reversal=document.createElement('canvas')
        this.bgcanvas_reversal.style.width = 800;
        this.bgcanvas_reversal.style.height = 600;
        this.bgcanvas_reversal.width = 800;
        this.bgcanvas_reversal.height = 600;
        this.bgctx_reversal=this.bgcanvas_reversal.getContext('2d')

        this.playerCanvas=document.createElement('canvas')
        this.playerCanvas.style.width = 800;
        this.playerCanvas.style.height = 600;
        this.playerCanvas.width = 800;
        this.playerCanvas.height = 600;
        this.playerCtx=this.playerCanvas.getContext('2d')

        this.speed=0.4
        this.distance=0
        this.bgIndex=0

        this.x=100
        this.y=260
        this.isMove=false
        this.direction=0
        this.moveSpeed=1
        this.score=0
        this.playTime=0
        this.meteorNumber=3
        this.lastClickTime=this.score

        this.draw=this.draw.bind(this)
        this.onmousemovebine=this.onmousemove.bind(this)

        window.onkeydown=this.onkeydown.bind(this)
        window.onkeyup=this.onkeyup.bind(this)

        this.gameSoundsource = new Howl({
            src: ['sound.webm', 'sound.mp3', 'sound.wav'],
            autoplay: true,
            loop: true,
            volume: 0.1,
            onend: function () {
                console.log('Finished!');
            }
        });
        this.bgmusic=this.gameSoundsource.play();
        this.attackmusic=this.gameSoundsource.play();
        this.diemusic=this.gameSoundsource.play();
    }
     gameSoundsource:any; //游戏声源控制
     bgmusic:any;
     attackmusic:any;
     diemusic:any;
     partnerMovePath:Array<any> //伙伴操作数据
     mainCanvas:HTMLElement|undefined; //主画板
     onmousemovebine:any;
     bubbles:Array<Bubble>; //点击气泡类
     bullets:Array<Bullet>; //子弹类
     meteors:Array<Meteor>; //流星
     particles:Array<Particle>; //粒子
     canvas:any; //操作路线缓冲画板
     ctx:any;
    bgcanvas:any; //游戏背景缓冲画板-正
    bgctx:any;
    bgcanvas_reversal:any; //游戏背景缓冲画板-反
    bgctx_reversal:any;

    imgData:any; //绘制伙伴数据前本地快照
    bgImg:any; //背景图片
    bgIndex:number; //背景图前后顺序 0:正在前 1：反在前
    speed:number; //背景移动速度
    distance:number; //周期距离

    playerImg:any; //玩家图片
    playerCanvas:any; //操作路线缓冲画板
    playerCtx:any;

    x:number;//玩家位置
    y:number;//玩家位置
    isMove:boolean; //是否移动
    direction:number; //移动方向
    moveSpeed:number; //移动速度

    lastClickTime:number;//上一次点击生效时间
    meteorNumber:number; //流星数量
    score:number; //游戏得分
    playTime:number; //游戏时间

    componentDidMount() {
/*        store.subscribe(()=>{
            if(store.getState().type==='messagechange'){
                this.imgData=this.ctx.getImageData(0,0,800,600)
                let list=JSON.parse(JSON.parse(store.getState().messagechange).content).movePath
                this.partnerMovePath.push(list)
                console.log(this.partnerMovePath)
            }
        })//接受伙伴数据
        let name=JSON.parse(localStorage.getItem('userInfo') || '{}')?.name
        let data={movePath:[], receiver:name==='a'?'b':'a',send:name}
        connect( 'wss://www.mrhuweb.cn/websoket?token='+(localStorage.getItem('token')|| '').replace(/"/g, ""),
            ()=>sendMessage('message', JSON.stringify(data))) //建立连接*/
        this.clock()
        this.init()
    }
    init(){
        let canvas:any=document.getElementById('game')
        canvas.onmousedown=this.onmousedown.bind(this)
        canvas.onmouseup=this.onmouseup.bind(this)
        canvas.width=800
        canvas.height=600
        this.mainCanvas=canvas

        this.bgImg=new Image()
        this.bgImg.src='/bg1.jpeg'
        this.playerImg=new Image()
        this.playerImg.src='/playerImg2.png'
    //    this.bgImg.src='/bg2.jpeg'
    //    this.bgImg.src='/bg3.gif'
    //    this.bgImg.src='/img.png'
        this.bgImg.onload=((e:any)=>{
            console.log('onload')
            this.bgctx.drawImage(
                this.bgImg,
                0,
                0,
                this.bgImg.width,
                this.bgImg.height,
                0,
                0,
                800,
                600
            );
            this.bgctx_reversal.save()
            this.bgctx_reversal.translate(800, 0);
            this.bgctx_reversal.scale(-1, 1);
            this.bgctx_reversal.drawImage(
                this.bgImg,
                0,
                0,
                this.bgImg.width,
                this.bgImg.height,
                0,
                0,
                800,
                600
            );
            this.bgctx_reversal.restore()
            this.bgctx_reversal.save()

        })

        this.playerImg.onload=((e:any)=>{
            this.playerCtx.drawImage(
                this.playerImg,
                0,
                0,
                this.playerImg.width,
                this.playerImg.height,
                this.x,
                this.y,
                80,
                80,
            );
        })
        this.setState(prevState=>({
            ...prevState,
            ctx:canvas.getContext('2d')
        }),()=>{
            this.imgData=this.ctx.getImageData(0,0,800,600)
            this.particles.push(new Particle(this.state.ctx,this.state.imgData))
            this.draw()
        })


    }
    clock(){
        setInterval(()=>{
            this.playTime++
            this.meteorNumber<300?this.meteorNumber++:this.meteorNumber=300
        },10)
    }
    onmousedown(e:any) { // 鼠标按下
        console.log(this.meteorNumber)
        if(this.meteorNumber>100){
            this.setState(prevState=>({
                ...prevState,
                x: e.offsetX,
                y: e.offsetY,
                isClickCanvas: true,
                now: [e.offsetX, e.offsetY],
                last: [e.offsetX, e.offsetY],
                imgData:this.state.ctx.getImageData(0,0,800,600)
            }),()=>{
                this.bubbles.push(new Bubble(this.state.ctx,this.state.imgData,this.state.last))
                let k=(this.state.last[1])/(800-this.state.last[0])
                this.meteors.push(new Meteor(this.state.ctx,this.state.imgData,k))
                console.log(k,this.meteors)
                this.lastClickTime=this.playTime
                this.meteorNumber-=100
                if(this.mainCanvas){
                    console.log(' 监听 鼠标移动事件')
                    this.mainCanvas.addEventListener('mousemove', this.onmousemovebine); // 监听 鼠标移动事件
                }
            })
        }
        console.log('鼠标按下',e.offsetX,e.offsetY)

    }
    onmousemove(e:any) { // 鼠标移动
        console.log('鼠标移动',this.state.last,this.state.now)
        this.setState(prevState=>({
            ...prevState,
            isMoveCanvas: true,
            endx: e.offsetX,
            endy : e.offsetY,
            width : e.offsetX - this.state.x,
            height: e.offsetX - this.state.y,
            last: [e.offsetX, e.offsetY],
            movePath:this.state.movePath.concat([[e.offsetX+0.5, e.offsetY+0.5]])
        }))
    }
    onkeydown(e:any) { // 键盘按下
        console.log('键盘按下',e.keyCode)
        if(e.keyCode){
            this.isMove=true
            this.direction=e.keyCode
        }
    }
    onkeyup(e:any){ // 键盘松开
        console.log('键盘松开',e.keyCode)
        this.isMove=false
        this.direction=0
    }
    //绘制操作
    line(){
        this.state.ctx.drawImage(this.canvas,0,0,800,600,0,0,800,600)
        this.ctx.clearRect(0,0,800,600)
        this.ctx.putImageData(this.imgData,0 ,0)

        this.ctx.lineWidth = 1;
        this.ctx.fillStyle = 'black';

        if(this.state.movePath.length>0){
            this.ctx.beginPath();
            this.ctx.moveTo(this.state.movePath[0][0], this.state.movePath[0][1]);
            this.state.movePath.forEach(item=>{
                this.ctx.lineTo(item[0], item[1]);
            })
            this.ctx.stroke();
        }


        if(this.partnerMovePath.length>0){
            this.partnerMovePath.forEach(path=>{
                this.ctx.beginPath();
                this.ctx.moveTo(this.partnerMovePath[0][0], this.partnerMovePath[0][1]);
                path.forEach((item: any[])=>{
                    this.ctx.lineTo(item[0], item[1]);
                })
                this.ctx.stroke();
            })

        }
    }

    //绘制玩家
    player(){
/*        if(this.state.movePath.length>0){
            this.playerCtx.clearRect(0,0,800,600)
            this.playerCtx.drawImage(
                this.playerImg,
                0,
                0,
                this.playerImg.width,
                this.playerImg.height,
                this.state.movePath[this.state.movePath.length-1][0]-40,
                this.state.movePath[this.state.movePath.length-1][1]-40,
                80,
                80,
            );
            this.state.ctx.drawImage(this.playerCanvas,0,0,800,600,0,0,800,600)
        }*/
        if(this.isMove){
            switch (this.direction) {
                case 37:
                    if(this.x>0){
                        this.x-=this.speed;
                    }
                    break;
                case 38:
                    if(this.y>0){
                        this.y-=this.speed;
                    }
                    break;
                case 39:
                    if(this.x<720){
                        this.x+=this.speed;
                    }
                    break;
                case 40:
                    if(this.y<520){
                        this.y+=this.speed;
                    }
                    break;
                default :break;
            }
        }
        this.playerCtx.clearRect(0,0,800,600)
        this.playerCtx.drawImage(
            this.playerImg,
            0,
            0,
            this.playerImg.width,
            this.playerImg.height,
            this.x,
            this.y,
            80,
            80,
        );
        this.state.ctx.drawImage(this.playerCanvas,0,0,800,600,0,0,800,600)
    }
    //绘制子弹
    bullet(){
        let len=Math.ceil(this.score/(1000/6))%20
        if(this.bullets.length<len){
            this.bullets.push(new Bullet(this.state.ctx,this.state.imgData))
        }
        this.bullets=this.bullets.filter(item=>item.x>0)
        this.bullets.forEach((item:Bubble)=>{
            item.drawStart()
        })
    }
    //点击涟漪
    bubble(){
        if(this?.bubbles && this.bubbles.length>0){
            this.bubbles.forEach((item:Bubble)=>{
                item.drawStart()
            })
        }
    }
    //点击流星
    meteor(){
        if(this?.meteors && this.meteors.length>0){
            this.meteors.forEach((item:Meteor)=>{
                item.drawStart()
            })
        }
    }
    //粒子
    particle(){
        if(this?.particles && this.particles.length>0){
            this.particles.forEach((item:Particle)=>{
                item.drawTail()
            })
        }
    }
    //绘制背景
    backgroundGeneration(){
        if(this.bgIndex){
            this.state.ctx.drawImage(this.bgcanvas_reversal,0+this.distance,0,800-this.distance,600,0,0,800-this.distance,600)
            this.state.ctx.drawImage(this.bgcanvas,0,0,this.distance,600,800-this.distance,0,this.distance,600)
        }else{
            this.state.ctx.drawImage(this.bgcanvas,0+this.distance,0,800-this.distance,600,0,0,800-this.distance,600)
            this.state.ctx.drawImage(this.bgcanvas_reversal,0,0,this.distance,600,800-this.distance,0,this.distance,600)
        }

        this.distance+=this.speed
        if(this.distance>=800){
            this.distance=0
            this.bgIndex===0?this.bgIndex=1:this.bgIndex=0
        }
    }
    playSroce(){
      this.state.ctx.font = '32px serif';
      this.state.ctx.strokeStyle  = 'red';
      this.state.ctx.beginPath();
      this.state.ctx.lineWidth = 1;
      this.state.ctx.moveTo(10,30);
        this.state.ctx.lineTo(100,30);
        this.state.ctx.lineTo(100,50);
        this.state.ctx.lineTo(10,50);
        this.state.ctx.closePath()
        this.state.ctx.stroke();
        this.state.ctx.fillStyle='red';
        this.state.ctx.fill();
        this.state.ctx.clearRect(0.3*this.meteorNumber+10,30,90-0.3*this.meteorNumber,20);
      this.state.ctx.strokeText('游戏得分：'+Math.ceil(this.score++/(1000/6)), 325, 50);
    }

    onmouseup() { // 鼠标抬起
        if (this.state.isClickCanvas) {
            console.log('鼠标抬起')
            this.setState(prevState=>({
                ...prevState,
                isClickCanvas: false,
            }),()=>{
                if(this.mainCanvas){
                    console.log('移除鼠标移动事件')
                    this.mainCanvas.removeEventListener('mousemove', this.onmousemovebine); // 移除鼠标移动事件
                }
                if (this.state.isMoveCanvas) { // 鼠标没有移动不保存
                    this.setState(prevState=>({
                        ...prevState,
                        isMoveCanvas: false,
                    }))
                    this.gatherImage(); // 保存每次的图像
                }
            })

        }
    }

    //操作数据传递，并保存本地快照
    gatherImage(){
        this.imgData=this.ctx.getImageData(0,0,800,600)
        let name=JSON.parse(localStorage.getItem('userInfo') || '{}')?.name
        let data={movePath:this.state.movePath, receiver:name==='a'?'b':'a',send:name}
        connect( 'wss://www.mrhuweb.cn/websoket?token='+(localStorage.getItem('token')|| '').replace(/"/g, ""),
            ()=>sendMessage('message', JSON.stringify(data)))
        this.setState(prevState=>({
            ...prevState,
            movePath: [],
        }))
    }
    bindMousemove(){

    }

    //统一绘制
    draw(){
    //    console.log('统一绘制',this)
       // this.state.ctx.globalCompositeOperation ='lighter'
        this.state.ctx.clearRect(0,0,800,600)
       this.backgroundGeneration()
       this.playSroce()
       this.player()
    //   this.bullet()
    //   this.bubble()
       this.meteor()
    //    this.particle()
    //    this.line()
        requestAnimationFrame(this.draw);
    }
    render() {
        return (
            <div className="page">
                <canvas id='game'></canvas>
            </div>
        );
    }
}
export default Game
