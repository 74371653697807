import React from "react";
import { CommentOutlined,UserOutlined } from '@ant-design/icons';
import {Avatar, Badge, Button, FloatButton} from "antd";
import {get} from "../../utils";
import './index.scss';
import {connect, sendMessage} from "../../utils/websocket";
import store from "../../reducer";
import {parseTime} from "../../utils/utils";
import TextArea from "antd/es/input/TextArea";



class Communicate extends React.Component<{},
    { name: string,
        reply:string,
        sendValue:string,
        sendP:string,
        sendId:any,
        userList:Array<Object>,
        messageList:Array<Object>,
        isOpen:Boolean
    }> {
    constructor(props: {}) {
        super(props);
        this.state={
            name:JSON.parse(localStorage.getItem('userInfo') || '{}')?.name,
            reply:'',
            sendValue:'',
            sendP:'',
            sendId:'',
            userList:[],
            isOpen:false,
            messageList:[]
        }
    }
    componentWillMount() {
        store.subscribe(()=>{
            console.log(store.getState())
            if(store.getState().type==='messagechange'){
                let list=[JSON.parse(JSON.parse(store.getState().messagechange).content)]
                this.setState(prevState=>({
                    ...prevState,
                    messageList:this.state.messageList.concat(list)
                }))
            }
        })
        this.getUserList()
    }
    /* When a user joins, I notify the server that a new user has joined to edit the document. */
    getUserList = async () => {
        let {data}=await get('getUserList')
        if(data.code===200){
            await this.setState(prevState=>({
                ...prevState,
                sendP:data.data.length>0?data.data[0].name:'',
                sendId:data.data.length>0?data.data[0].id:'',
                userList:data.data
            }),() => {
                console.log(this.state)
                this.getMessageRecordList()
            })
        }
    }
    getMessageRecordList=async ()=>{
        let params={id:JSON.parse(localStorage.getItem('userInfo') || '{}').id,sendId:this.state.sendId}
        let {data} = await get('getMessageRecordList',{params})
        console.log(data)
        if(data.code===200){
            let list=data.data.map((item:any)=> {return {
                send:item.sender,
                receive:item.receiver,
                text:item.message}})
            list.unshift(
                {
                    send:'a',
                    receive:'b',
                    text:'快来和我聊天吧'
                }
            )
            this.setState(prevState=>({
                ...prevState,
                messageList:list
            }))
        }
    }
    selectUser=async (item:any)=>{
        this.setState(prevState=>({
            ...prevState,
            sendId:item.id,
            sendP:item.name,
        }),()=>this.getMessageRecordList())}
    open=()=>{
        this.getUserList()
        this.setState(prevState=>({
            ...prevState,
            isOpen:true,
        }))

    }
    close=()=>{
        this.setState(prevState=>({
            ...prevState,
            isOpen:false,
        }))
    }
    userList=()=>{
        return (<div>
            {this.state.userList.map((item:any) => (
                <div className={this.state.sendP===item.name?'item-active person-item':'person-item'} key={item.id} onClick={(e: any):any=>this.selectUser(item)}>
                    <Badge count={1}>
                        <Avatar  icon={<UserOutlined />} />
                    </Badge>
                    <div className="person-content">
                        <p>
                            <span className="name">{item.name}</span>
                            <span className="time">{item.time || parseTime(new Date(), '{h}:{i}')}</span>
                        </p>
                        <p className="message-text">消息消息消息消息消息消息</p>
                    </div>
                </div>
            ))}
        </div>)
    }
    messageRecording=()=>{
        return (<div className="recording">
            {this.state.messageList.map((item:any,index) => (
               item.send===this.state.name
                   ?
               <div className="recording-item" key={item.text+index} style={{float:"right"}}>
                   <div className="recording-content">{item.text || '无消息'}</div>
                   <Avatar  icon={<UserOutlined />} />
               </div>
                   :
               <div className="recording-item" key={item.text+index} >
                   <Avatar  icon={<UserOutlined />} />
                   <div className="recording-content">{item.text || '无消息'}</div>
               </div>
            ))}
        </div>)
    }
    editMessage=()=>{
        return (<div className="editMessage">
            <TextArea bordered={false} autoSize={{minRows: 8, maxRows: 8}} style={{ width: '100%' }} value={this.state.sendValue} onChange={(e: any):any=>this.onStateChange(e)}/>
            <div className="editMessage-bt">
                <Button onClick={ (event: any):any=> this.close()}>关闭</Button>
                <Button className="margin-h20" type='primary' onClick={ (event: any):any=> this.onEditorStateChange()}>发送</Button>
            </div>

        </div>)
    }

    /* When content changes, we send the current content of the editor to the server. */
    onEditorStateChange = () => {
        if(this.state.sendP){
            let data={text:this.state.sendValue, receiver:this.state.sendP,send:this.state.name}
            connect( 'wss://www.mrhuweb.cn/websoket?token='+(localStorage.getItem('token')|| '').replace(/"/g, ""),
                ()=>sendMessage('message', JSON.stringify(data))
                /* (data:any)=>{
                     console.log(data)
                     this.setState(prevState=>({
                         ...prevState,
                         reply:data.text,
                     }))
             }*/)
            this.setState(prevState=>({
                ...prevState,
                messageList:this.state.messageList.concat([data]),
                sendValue:'',
            }))
        }
    };

    onStateChange = (e:any) => {
        this.setState(prevState=>({
            ...prevState,
            sendValue:e.target.value,
        }))
    };

    componentDidMount(){

    }

    render() {
        return (
            <div className={this.state.isOpen?"message-dialog":''}>
                <FloatButton icon={<CommentOutlined />} onClick={(e)=>{this.open()}}/>
                {this.state.isOpen?<div className="message-content">
                    <div className="left">
                        {this.userList()}
                    </div>
                    <div className="right">
                        {this.messageRecording()}
                        {this.editMessage()}
                    </div>
                </div>:''}
            </div>
        )
    }
}
export default Communicate;
