class Bullet {
    canvas:any;
    ctx:any;
    content:CanvasRenderingContext2D;
    imgData:any;
    transparency:any;
    size:any;
    x:number;
    y:number;
    fillStyle:Array<number>;
    constructor(ctx:CanvasRenderingContext2D,imgData:any) {
        this.content=ctx;
        this.imgData=imgData;
        this.transparency=1
        this.size=Math.random()*4+1
        this.x=800
        this.y=Math.random()*590+5
        this.fillStyle=[Math.random()*255,Math.random()*255,Math.random()*255];
        this.canvas=document.createElement('canvas')
        this.canvas.width = 800;
        this.canvas.height = 600;
        this.ctx=this.canvas.getContext('2d')
    }
    drawStart(){
        if(this.x>=0){
            this.x-=2
            this.ctx.clearRect(0,0,800,600)
            //   this.content.putImageData(this.imgData,0,0)
            this.ctx.beginPath();
            this.ctx.lineWidth = 1;
            this.ctx.fillStyle = `rgb(${this.fillStyle[0]},${this.fillStyle[1]},${this.fillStyle[2]},1)`;
            this.ctx.arc(this.x,this.y, this.size, 0, 2 * Math.PI);
            this.ctx.closePath();
            this.ctx.fill();

            for(let i=0;i<60;i++){
                this.drawTail(i)
            }


            this.content.drawImage(this.canvas,0,0)
        }
    }
    drawTail(opacity:number){
        console.log(opacity)
        let transparency
        if(opacity<20){
            transparency =this.transparency-0.03*opacity
        }else {
            transparency =0.4-0.01*(opacity-20)
        }

        let x=this.x+(this.size*40/50)*opacity
        if(transparency>0){
            this.ctx.beginPath();
            this.ctx.fillStyle = `rgb(${this.fillStyle[0]},${this.fillStyle[1]},${this.fillStyle[2]},${transparency})`;
            this.ctx.arc(x,this.y, this.size, 0, 2 * Math.PI);
            this.ctx.closePath();
            this.ctx.fill();
        }
    }
}

export default Bullet

