import React, {useEffect, useState} from 'react';
import {Outlet, useLocation} from 'react-router-dom';
import store from "../reducer";
import axios from "axios";

async function fun(num:number){
    let a: number[]=[]
    let arr: number[][]=[]
    return  new Promise((resolve, reject) => {
        let t= setInterval(()=>{
            let d: number=Math.ceil(Math.random()*33);
            if(!a.includes(d)){
                a.push(d)
            }
            if(a.length===6){
                a.sort((x,z)=>{return x-z})
                a.push(Math.ceil(Math.random()*16))
                arr.push(a)
                a=[]
                if(--num===0){
                    clearInterval(t)
                    resolve(arr)
                }
                console.log(num)
            }
        },1000)
    })
}

async function download() {
    let a = document.createElement('a') //创建一个<a></a>标签
    a.href = 'https://www.mrhuweb.cn/player.png' // 将流文件写入a标签的href属性值
    a.download = 'player.png' //设置文件名
    a.style.display = 'none' // 障眼法藏起来a标签
    document.body.appendChild(a) // 将a标签追加到文档对象中
    a.click() // 模拟点击了a标签，会触发a标签的href的读取，浏览器就会自动下载了
    a.remove() // 一次性的，用完就删除a标签
}

export default function MyMain() {
    const location = useLocation();
    const [toColorBoll,setToColorBoll] = useState<number[][]>([]);
    const [generate,setGenerate] = useState<number>(0);
    useEffect( () => {
        console.log('MyMain-useEffect')
        fun(5).then((res:any)=>{
            setToColorBoll(res)
        })
        store.dispatch({type: 'path', data: location.pathname});
    },[generate]);
        return (
            <div>
                <div className="App-main">
                    <button onClick={()=>{setGenerate(Date.now)}}>生成</button>
                    <button onClick={()=>{download()}}>下载</button>
                    <ul>
                        {toColorBoll.map((item: any,index:number) => (
                            <li key={index}>{item.join(',')}</li>
                        ))}
                    </ul>
                    <Outlet />
                </div>
            </div>
        )
}


